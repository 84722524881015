import React, { useState } from 'react';
import 'presentations/assets/css/app.css';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import { JSONInstructionMerchant } from 'presentations/assets/JSONAsset';
import i18next from 'i18next';

function PaymentInstruction({ name, instructions }) {
    const [showInstruction, setShowInstruction] = useState(false);

    return (
        <>
            <button className="w-full lg:mt-3" onClick={() => setShowInstruction(!showInstruction)}>
                <div className="bg-white items-center px-4 py-3 flex flex-row justify-between mt-2 lg:mt-0">
                    <p className="text-category text-gray-hero_title font-face-poppinsbold">{name}</p>
                    {showInstruction ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                </div>
            </button>

            <Collapse in={showInstruction}>
                <div className="bg-white px-4 py-3">
                    <div>
                        {instructions && (i18next.language === 'en'
                            ? instructions?.data[0]?.instruction_en
                            : instructions?.data[0]?.instruction_id
                        ).map((question) => {
                            return (
                                <div className="flex flex-row h-auto w-full justify-between mb-2" key={question.sequence}>
                                    <div className="w-1/12 ">
                                        <p className="text-xs text-gray-hero_title font-face-poppinsregular text-center">
                                            {question.sequence}.
                                        </p>
                                    </div>
                                    <div className="w-11/12 ml-2">
                                        <p className="text-xs text-gray-hero_title font-face-poppinsregular">
                                            {question.description}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Collapse>
        </>
    );
}

export default PaymentInstruction;
