import React, { useState } from 'react';
import PaymentCategory from './PaymentCategory';
import Principal from 'presentations/assets/images/principal.png';
import PaymentPartner from './PaymentPartner';
import { useTranslation } from 'react-i18next';
import PaymentPartnerCollapse from './PaymentPartnerCollapse';

const ExternalPaymentSection = (props) => {
    const { externalPayment, VA, CC, onChanged, selectedValue } = props;
    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: onChanged,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });
    const [expandCC, setExpandCC] = useState(false);
    const [expandVA, setExpandVA] = useState(false);
    const [expandAdd, setExpandAdd] = useState(false);

    const { t } = useTranslation();
    if (externalPayment !== null || externalPayment?.length !== 0) {
        return (
            <div>
                {externalPayment.filter((word) => word.category === VA).length !== 0 ? (
                    <div className={`flex flex-col px-3.5 rounded-2xl ${ (externalPayment.filter((word) => word.category === CC).length !== 0) &&
                     (externalPayment.filter((word) => word.category === CC).length !== 0) && 'my-4'}`}>
                        <PaymentCategory type={t('va')} isExpand={expandVA} setIsExpand={setExpandVA} />
                        {expandVA ? (
                            externalPayment
                                .filter((word) => word.category === VA)
                                .map((va_item, index, array) => {
                                    return (
                                        <PaymentPartner
                                            last={(array.length - 1 === index)}
                                            key={index}
                                            items={va_item}
                                            onChanged={controlProps(va_item.partner_provider)}
                                        />
                                    );
                                })
                        ) : (
                            <PaymentPartnerCollapse data={externalPayment.filter((word) => word.category === VA)} />
                        )}
                    </div>
                ) : null}
                {externalPayment.filter((word) => word.category === CC).length !== 0 ? (
                    <div className={`flex flex-col px-3.5 rounded-2xl ${(externalPayment.filter((word) => word.category === '').length !== 0) && 'my-4'}`}>
                        <div className="flex flex-row items-center">
                            <PaymentCategory type={t('cc')} isExpand={expandCC} setIsExpand={setExpandCC} />
                        </div>
                        {expandCC ? (
                            externalPayment
                                .filter((word) => word.category === CC)
                                .map((cc_item, index, array) => {
                                    return (
                                        <PaymentPartner
                                        last={(array.length - 1 === index)}
                                            key={index}
                                            items={cc_item}
                                            onChanged={controlProps(cc_item.partner_provider)}
                                        />
                                    );
                                })
                        ) : (
                            <PaymentPartnerCollapse data={externalPayment.filter((word) => word.category === CC)} />
                        )}
                    </div>
                ) : null}
                {externalPayment.filter((word) => word.category === '').length !== 0 ? (
                    <div className="flex flex-col px-3.5 rounded-2xl">
                        <PaymentCategory type={t('additional')} isExpand={expandAdd} setIsExpand={setExpandAdd} />
                        {expandAdd ? (
                            externalPayment
                                .filter((word) => word.category === '')
                                .map((items, index, array) => {
                                    return (
                                        <PaymentPartner
                                            last={array.length - 1 === index}
                                            key={index}
                                            items={items}
                                            onChanged={controlProps(items.partner_provider)}
                                        />
                                    );
                                })
                        ) : (
                            <PaymentPartnerCollapse data={externalPayment.filter((word) => word.category === '')} />
                        )}
                    </div>
                ) : null}
            </div>
        );
    }
    return null;
};

export default ExternalPaymentSection;
