import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import 'utilities/i18n';
import { getStaticConnetingPage, submitPaymentToElisa } from 'domain/use-case';
import { redirectPayment } from 'core/controller';
import { setLoadingState } from 'adapters';
import {
    checkDevice,
    checkFormatPhone,
    CREDIT_CARD,
    FINNET_VA,
    FINNET_WALLET,
    GOPAY,
    MCP_VA,
    OVO,
    phoneNumber,
    transformURLPartner,
    VIRTUAL_ACCOUNT,
    XENDIT_INVOICES,
} from 'utilities';
import DOMPurify from 'dompurify';
import LinkAjaWco from '../landing-page/Services/LinkAjaWco';

export default function ConnectingPageViewModel() {
    const { state } = useLocation();
    const stateGlobal = useSelector((states) => states.upp_data);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    let { partner } = useParams();
    const { body, total, token } = state;
    const [number, setNumber] = useState('');
    const [error, setError] = useState(false);
    const [isLinkAjaWco, setIsLinkAjaWco] = useState(false);
    const [linkajaUrl, setLinkajaUrl] = useState('');
    const [linkajaAuth, setLinkajaAuth] = useState('');
    const [showModalAlert, setShowModalAlert] = useState(false);
    const [instruction, setInstruction] = useState(null);
    const [deviceType, setDeviceType] = useState('');
    const [loadingTnC, setLoadingTnC] = useState(false);
    const sanitizer = DOMPurify.sanitize;
    const [paymentId, setPaymentID] = useState('');

    const goToPreviousPath = () => {
        navigate(-1);
    };

    const getStaticConnectingPageView = async (token) => {

        setLoadingTnC(true);
        let reqBody = {
            payment_provider: body.payment_info.payment_provider,
            bank_name: body.payment_info.bank_name,
            platform: stateGlobal.device ? stateGlobal.device.toLowerCase() : checkDevice().toLocaleLowerCase(),
        };
        // let reqBody = {
        //     payment_provider: body.payment_info.payment_provider,
        //     bank_name:
        //         AGGREGATOR_PARTNER.includes(body.payment_info.payment_provider) ||
        //         XENDIT_WALLET.includes(body.payment_info.payment_provider)
        //             ? body.payment_info.bank_name
        //             : '',
        //     platform: stateGlobal.device.toLowerCase() ? stateGlobal.device.toLowerCase() : deviceType,
        // };
        try {
            const response = await getStaticConnetingPage(token, reqBody);
            if (response && response.status_code === '00') {
                setInstruction(response);
                dispatch(setLoadingState({ loading: false }));
            } else {
                dispatch(setLoadingState({ loading: false }));
            }
            setLoadingTnC(false);
        } catch (error) {
            setLoadingTnC(false);
            dispatch(setLoadingState({ loading: false }));
        }
    };

    useEffect(() => {
        setDeviceType(checkDevice());
        dispatch(setLoadingState({ loading: true }));
        getStaticConnectingPageView(token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // submit payment based on condition
    const submitOrdertoElisa = async () => {
        if (body.upp_type === 'indirect_with_order' && body.external_channel === false) {
            if (partner === GOPAY) {
                setPaymentID('');
            } else {
                setPaymentID(body.customer_details.cust_phone);
            }
            // if upp_type === indirect_with_order and external channel false, redirect to redirect_url_payment and append some params,
            redirectToPaymentUrl();
            //  call Elisa API if upp_type is indirect_without_order or upp_type is indirect_with_order and external_channel true
        } else if (partner === OVO) {
            // submit payment with ovo
            submitPaymentOVO();
        } else if (body.payment_info.payment_provider === CREDIT_CARD) {
            // redirect to credit card page
            navigateToCreditCardPage();
        } else {
            // submit order to Elisa
            submitPayment();
        }
    };

    // submit order to Elisa
    const submitPayment = async () => {
        dispatch(setLoadingState({ loading: true }));
        try {
            if (body.payment_info.payment_provider === FINNET_WALLET && body.payment_info.bank_name === OVO) {
                body.payment_info.payment_id = body.customer_details.cust_phone;
            }

            const response = await submitPaymentToElisa(body.payment_info, token);
            if (response) {
                dispatch(setLoadingState({ loading: true }));
                if (response.payment_type === 4) {
                    // perform post to linkaja web checkout
                    LinkAjaWco(
                        response.content,
                        response.payment_url,
                        dispatch,
                        { setLinkajaAuth, setLinkajaUrl, setIsLinkAjaWco },
                        setError
                    );
                } else if (response.status_code === '00') {
                    // redirect to page or url after getting response from Elisa
                    redirectPayment(response, body, navigate, total);
                } else {
                    window.location.replace(body.redirect_url_failed);
                }
            }
        } catch (e) {
            setError(true);
            dispatch(setLoadingState({ loading: false }));
        }
    };

    const submitPaymentOVO = async () => {
        // get phone number and insert to body for call Elisa Payment API
        if (number !== '') {
            dispatch(setLoadingState({ loading: true }));
            if (checkFormatPhone(number)) {
                body.payment_info.payment_id = phoneNumber(number);
            } else {
                body.payment_info.payment_id = `62${number}`;
            }
            const payload = {
                payment_id: body.payment_info.payment_id,
                payment_provider: body.payment_info.payment_provider,
                bank_name: body.payment_info.bank_name
            };
            try {
                const response = await submitPaymentToElisa(payload, stateGlobal.token);
                if (response.status_code === '00') {
                    window.location.replace(response.payment_url);
                } else {
                    window.location.replace(body.redirect_url_failed);
                }
            } catch (e) {
                setError(true);
                dispatch(setLoadingState({ loading: false }));
            }
        } else {
            // cannot submit empty number
            setShowModalAlert(true);
        }
    };

    // navigate to credit card form input page
    const navigateToCreditCardPage = () => {
        dispatch(setLoadingState({ loading: true }));
        navigate('/creditcardform', {
            state: {
                detail: body.item_details,
                payment_info: body.payment_info,
                app: body.channel,
                body: body,
                total: total,
                redirect: body.redirect_url,
                failed: body.redirect_url_failed,
                token: token,
            },
        });
    };

    // indirect with order redirection
    const redirectToPaymentUrl = () => {
        const paymentProvider = body.payment_info.payment_provider;
        const bankName = body.payment_info.bank_name;
        switch (true) {
            case partner === OVO:
                window.location.replace(
                    transformURLPartner(body, number, null, null, stateGlobal.total_price, stateGlobal.partner_price)
                );
                break;
            case partner === CREDIT_CARD:
                navigateToCreditCardPage();
                break;
            case paymentProvider === VIRTUAL_ACCOUNT ||
                paymentProvider === FINNET_VA ||
                paymentProvider === MCP_VA ||
                paymentProvider === XENDIT_INVOICES:
                window.location.replace(
                    transformURLPartner(body, null, bankName, null, stateGlobal.total_price, stateGlobal.partner_price)
                );
                break;
            default:
                window.location.replace(
                    transformURLPartner(
                        body,
                        paymentId,
                        bankName,
                        null,
                        stateGlobal.total_price,
                        stateGlobal.partner_price
                    )
                );
                break;
        }
    };

    return {
        isLinkAjaWco,
        error,
        linkajaAuth,
        linkajaUrl,
        goToPreviousPath,
        partner,
        number,
        setNumber,
        submitOrdertoElisa,
        showModalAlert,
        setShowModalAlert,
        instruction,
        setInstruction,
        sanitizer,
        deviceType,
        setDeviceType,
        getStaticConnectingPageView,
        loadingTnC,
        body,
    };
}
