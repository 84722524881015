import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconPhone from '../../../assets/images/icon_phone.svg';

// unbinding confirmation modal
function ModalInquiry({ selectInquiry, onChangeIquiry, reqOtp, dataInquiry, onClose }) {
    const { t } = useTranslation();
    return (
        <>
            <div className="justify-center items-center flex overflow-hidden fixed bottom-0 lg:inset-0 z-50 outline-none focus:outline-none">
                <div className="opacity-75 fixed inset-0 z-51 bg-blue-dark_blue" onClick={onClose} data-testid="modal-background"></div>
                <div className="relative w-full mx-auto lg:max-w-none z-52">
                    {/*content*/}
                    <div className="border-0 lg:w-1/4 lg:mx-auto rounded-t-xl lg:rounded-b-xl shadow-lg relative flex flex-col w-screen bg-white outline-none focus:outline-none px-4 pb-6 pt-3">
                        <button
                            className="rounded-xl h-0.5 w-24 bg-gray-pull mx-auto lg:hidden hover:bg-gray-700"
                            onClick={onClose}
                        />
                        <button
                            className="hidden sm:block lg:flex lg:absolute lg:right-3 lg:top-3 text-gray-pull hover:text-gray-700"
                            onClick={onClose}
                        >
                            <CloseIcon color="inherit" />
                        </button>
                        {/*body*/}
                        <div className="relative flex-auto w-full lg:w-auto mt-6">
                            <p className="text-base font-face-tselbold">{t('choose_inquiry')}</p>
                            <p className="mt-4 text-xs font-face-poppinsregular">
                                {dataInquiry?.length} {t('choose_inquiry_bca_dd')}
                            </p>
                        </div>
                        <div className="flex w-full flex-col">
                            {dataInquiry?.map((item, index) => {
                                const isSelected = selectInquiry === `${item.phone_number}-${item.phone_id}`;
                                return (
                                    <div
                                        key={index}
                                        className={`flex p-4 mt-5 justify-start bg-gray-200 rounded-2xl ${
                                            isSelected ? 'border-red-error border-2' : ''
                                        }`}
                                        onClick={() => onChangeIquiry(`${item.phone_number}-${item.phone_id}`)}
                                    >
                                        <img src={IconPhone} alt="icon-phone" className="w-6 h-6 mr-6" />
                                        <p>{item.phone_number}</p>
                                    </div>
                                );
                            })}
                        </div>
                        {/*footer*/}
                        {selectInquiry && (
                            <div className="flex items-center flex-col mt-6">
                                <button
                                    className="bg-red-tsel w-full text-white active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={reqOtp}
                                >
                                    {t('continuebutton')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalInquiry;
