import React, { useState } from 'react';
import { AGGREGATOR_PARTNER } from 'utilities';
import PaymentCategory from './PaymentCategory';
import PaymentPartner from './PaymentPartner';
import { useTranslation } from 'react-i18next';
import PaymentPartnerCollapse from './PaymentPartnerCollapse';

const RecommendationSection = (props) => {
    const {
        onChanged,
        recomendationData,
        selectedValue,
        token,
        body,
        onDirectOpenBindingPage,
        onBind,
        onUnbind,
        checkBinding,
    } = props;

    const { t } = useTranslation();
    const [expand, setExpand] = useState(false);
    // set selected partner
    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: onChanged,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });

    if (recomendationData && recomendationData.length !== 0) {
        return (
            <div className="flex flex-col px-3.5 mb-4 rounded-2xl">
                {recomendationData.length !== 0 ? (
                    <PaymentCategory type={t('recommendation')} isExpand={expand} setIsExpand={setExpand} />
                ) : null}
                {expand ? (
                    recomendationData.length !== 0 &&
                    recomendationData.map((item, index, array) => {
                        return (
                            <PaymentPartner
                                last={array.length - 1 === index}
                                key={index}
                                items={item}
                                isChecked={item.partner_provider === selectedValue.split('|')[0]}
                                onBind={onBind}
                                onUnbind={onUnbind}
                                onCheckBinding={checkBinding}
                                token={token}
                                body={body}
                                onDirectBinding={onDirectOpenBindingPage}
                                onChanged={controlProps(
                                    item.partner_provider + '|' + (item.bank_name ? item.bank_name : '')
                                )}
                            />
                        );
                    })
                ) : (
                    <PaymentPartnerCollapse data={recomendationData} />
                )}
            </div>
        );
    }
    return null;
};

export default RecommendationSection;
