
// code separator for payment code
export const code_separators = (num) => {
    let num_parts = num.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{4})+(?!\d))/g, '-');
    return num_parts.join('.');
};

export const handleSelect = (value) => ({
    provider: value.split('|')[0],
    name: value.split('|')[1],
});
