import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Description,
    UnbindButton,
    PartnerDisplayName,
    BalanceDisplayName,
    DisabledMessage,
} from './PaymentPartnerComponent';

import { PaymentPartnerLogo } from './PaymentPartnerLogo';
import Radio from '@mui/material/Radio';
import { BRI_DD, MANDIRI_DD, BNI_DD, BCA_DD } from 'utilities';
import { setPaymentInfo } from 'adapters';
import { useDispatch } from 'react-redux';

const PaymentPartner = (props) => {
    const {
        items,
        balance_nominal,
        logo,
        title,
        onChanged,
        balance,
        token,
        body,
        onBind,
        isChecked,
        onUnbind,
        onCheckBinding,
        last,
    } = props;

    const { t } = useTranslation();
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const isDisable = () => {
        if (items.flag && items.flag === 2) {
            return 'opacity-50';
        }
        return '';
    };

    const isOfferAndDirectDebit = () => {
        return (
            (items.is_offer_to_customer &&
                items.method &&
                items.method.includes('direct_debit') &&
                items.method.length > 1) ||
            (!items.is_offer_to_customer && items.method && !items.method.includes('direct_debit')) ||
            (items.method && !items.method.includes('direct_debit'))
        );
    };

    const isNeedRecheckBindingStatus = () => {
        return (
            items.bind_info &&
            items.bind_info.status &&
            items.bind_info.status !== 'unbinding' &&
            items.bind_info.status !== 'binding'
        );
    };

    const DirectDebitButton = () => {
        if (isOfferAndDirectDebit()) {
            return (
                // show radio button if is offered to customer whether using direct debit or non direct debit
                <Radio
                    {...onChanged}
                    disabled={items.flag && items.flag === 2}
                    sx={{
                        color: '#001A41',
                        '&.Mui-checked': {
                            color: '#0050AE',
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: 24,
                        },
                    }}
                />
            );
        } else {
            return (
                // show force connect/binding text button if direct debit partner and offer to customer is false
                <button
                    className={`flex flex-row items-center pt-3 pb-3 hover:bg-white disabled:bg-white ${isDisable()}`}
                    disabled={items.flag && items.flag === 2}
                    onClick={directBindingPage}
                >
                    <p className={`text-xs mr-1.5 font-face-poppinsregular text-red-tsel ${isDisable()}`}>
                        {t('connect')}
                    </p>
                </button>
            );
        }
    };

    const Button = () => {
        if (items.bind_info && items.bind_info.status === 'unbinding' && items.method.length == 1) {
            return <DirectDebitButton />;
        } else if (isNeedRecheckBindingStatus()) {
            return (
                // show reload button if error getting check status binding
                <button className="pt-3 pb-3 w-1/4" disabled={items.flag && items.flag === 2} onClick={onCheckBinding}>
                    <p className={`text-xs font-face-poppinsbold text-blue-tselproduct ${isDisable()}`}>
                        {t('reload')}
                    </p>
                </button>
            );
        } else {
            return (
                <Radio
                    {...onChanged}
                    disabled={items.flag && items.flag === 2}
                    sx={{
                        color: '#001A41',
                        '&.Mui-checked': {
                            color: '#0050AE',
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: 24,
                        },
                    }}
                />
                // show normal radio button
            );
        }
    };

    // perform binding without offer to customer for gopay, dana and mandiri direct debit
    const directBindingPage = async () => {
        if (items.partner_provider === MANDIRI_DD) {
            onBind(items.partner_provider);
        } else if (items.partner_provider === BCA_DD) {
            onBind(items.partner_provider);
        } else if (items.partner_provider === BNI_DD) {
            const _tempData = body;
            _tempData.payment_info.payment_provider = items.partner_provider;
            dispatch(setPaymentInfo({ payment_info: body.payment_info }));
            navigate('/binding/bni_dd', {
                state: {
                    token: token,
                    channel: body.channel,
                    payment_info: body.payment_info,
                    customer_info: body.customer_details,
                    redirect: body.redirect_url,
                    failed: body.redirect_url_failed,
                    partner_credential: items,
                },
            });
        } else if (items.partner_provider === BRI_DD) {
            const _tempData = body;
            _tempData.payment_info.payment_provider = items.partner_provider;
            dispatch(setPaymentInfo({ payment_info: body.payment_info }));
            navigate('/directdebit', {
                state: {
                    token: token,
                    channel: body.channel,
                    payment_info: body.payment_info,
                    customer_info: body.customer_details,
                    redirect: body.redirect_url,
                    failed: body.redirect_url_failed,
                    partner_credential: items,
                },
            });
        } else {
            const _tempData = body;
            _tempData.payment_info.payment_provider = items.partner_provider;
            body.payment_info.bank_name = items.bank_name;
            navigate('/binding', {
                state: {
                    token: token,
                    channel: body.channel,
                    payment_info: body.payment_info,
                    customer_info: body.customer_details,
                    redirect: body.redirect_url,
                    failed: body.redirect_url_failed,
                    partner_credential: items,
                },
            });
        }
    };

    return (
        <div
            className={`px-3.5 py-3.5 bg-white border-b-2 border-opacity-70 h-auto w-full self-center ${
                last && 'rounded-b-2xl'
            }`}
        >
            <div className="flex w-full">
                <div className=" w-full">
                    <div
                        className={`py-2.5 flex flex-row justify-between bg-white items-center h-auto ${
                            items.description && items.description !== '' && items.description !== 'null'
                                ? 'py-0 max-h-10 pt-2.5'
                                : ''
                        }`}
                    >
                        <div className="flex-row flex items-center w-full">
                            <div className={`max-h-6 w-6 flex-none flex ${isDisable()}`}>
                                <PaymentPartnerLogo parameter={true} logo={logo} title={title} items={items} />
                            </div>
                            <div className="w-full lg:w-full">
                                {title === 'balance' ? (
                                    // balance display name
                                    <BalanceDisplayName
                                        isDisable={isDisable}
                                        balance={balance}
                                        balance_nominal={balance_nominal}
                                    />
                                ) : (
                                    // show payment partner display name
                                    <PartnerDisplayName items={items} isDisable={isDisable} />
                                )}
                            </div>
                        </div>
                    </div>
                    {/* show description */}
                    <Description items={items} isDisable={isDisable} isChecked={isChecked} />
                    {/* show unbind text button if account already binding */}
                    <UnbindButton items={items} onUnbind={onUnbind} isChecked={isChecked} t={t} />
                </div>
                {/* implement show button logic */}
                <div className=" flex items-center justify-center">
                    <Button />
                </div>
            </div>
            {/* show disabled message if any */}
            <DisabledMessage items={items} />
        </div>
    );
};

export default PaymentPartner;
