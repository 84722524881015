import { TotalPrice } from "presentations/shared";

export const getPartnerPriceHandle = (inputBody, payment_info) => {
    const price = {
        partner_price: 0,
        item_fee: 0,
        platform_fee: 0,
        TotalPrice: 0,
    }

    const selected = selectedPayment(inputBody, payment_info);
    if (selected.length > 0) {
        price.partner_price = selected[0].partner_price || 0;
        price.item_fee = selected[0].item_fee || 0;
        price.platform_fee = selected[0].platform_fee || 0;
    } else {
        price.partner_price = inputBody.item_details?.item_price || 0;
        price.item_fee = inputBody.item_details?.item_fee || 0;
        price.platform_fee = inputBody.item_details?.platform_fee || 0;
    }

    price.TotalPrice = TotalPrice(price.partner_price, price.item_fee, price.platform_fee).total;

    return price;
};

export const selectedPayment = (inputBody, payment_info) => {
    return inputBody.list_payment_partner.filter(
        (item) =>
            item.partner_provider === payment_info.payment_provider &&
            (item.bank_name === payment_info.bank_name ||
                'binding' === payment_info.bank_name ||
                '' === payment_info.bank_name ||
                'gopay' === payment_info.bank_name)
    );
};
