import React, { useState } from 'react';
import 'presentations/assets/css/app.css';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ScanQRIS from 'presentations/assets/images/ScanQRIS.png';
import i18next from 'i18next';

export default function ScanQRISInstruction({ instruction }) {
    const [showInstruction, setShowInstruction] = useState(false);

    return (
        <>
            <div className="h-2 bg-gray-200"></div>
            <button className="w-full h-auto" onClick={() => setShowInstruction(!showInstruction)}>
                <div className="bg-white items-center px-4 py-3 flex flex-row justify-between lg:mt-0">
                    <h2 className="font-face-poppinsbold text-left text-xs text-gray-hero_title">{i18next.language === 'en' ? instruction?.title_en : instruction?.title_id}</h2>
                    {showInstruction ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                </div>
            </button>

            <Collapse in={showInstruction}>
                <div className="bg-white p-4">
                    {instruction && (i18next.language === 'en'
                        ? instruction?.instruction_en
                        : instruction?.instruction_id
                    ).map((question) => {
                        return (
                            <div key={question.key}>
                                <div className="flex flex-row h-auto w-full justify-between mb-2">
                                    <div className="w-1/12 lg:w-6">
                                        <p className="text-link_download font-face-poppinsregular text-center text-gray-hero_title">
                                            {question.sequence}.
                                        </p>
                                    </div>
                                    <div className="w-11/12 lg:mr-5">
                                        <p className="text-link_download font-face-poppinsregular text-gray-hero_title">
                                            {question.description}
                                        </p>
                                    </div>
                                </div>
                                {question.sequence === 2 ? <img src={ScanQRIS} alt="Download QRIS" className="mb-4" /> : null}
                            </div>
                        );
                    })}
                </div>
            </Collapse>
        </>
    );
}
