import React from 'react';
import { Instruction } from './Instruction';

function PaymentCodeInstructionSection({ payment_info, partner, instructions}) {
    return (
        <>
            <Instruction payment_info={payment_info} partner={partner} instructions={instructions}/>
        </>
    );
}

export default PaymentCodeInstructionSection;
