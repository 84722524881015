// get params from url
export const getParams = () => {
    const query = new URLSearchParams(window.location.search);
    const partner = query.get('partner');
    const channel = query.get('channel');
    const token = query.get('token');
    const limit = query.get('limit');
    const binded = query.get('binded');
    const auth_code = query.get('auth_code') || query.get('authCode');
    return {
        partner: partner,
        channel: channel,
        token: token,
        auth_code: auth_code,
        limit: limit,
        binded: binded,
    };
};

// get params from url for dana direct debit
export const getDanaAuthParams = () => {
    const query = new URLSearchParams(window.location.search);
    const auth_code = query.get('auth_code');
    const channel = query.get('channel');

    return {
        auth_code: auth_code,
        channel: channel,
    };
};
