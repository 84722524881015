import { setLoadingState } from "adapters";


const LinkAjaWco = async (auth, action, dispatch, state, setError ) => {
  dispatch(setLoadingState({ loading: false }));
  try {
      if (auth) {
          state.setLinkajaAuth(auth);
          state.setLinkajaUrl(action);
          state.setIsLinkAjaWco(true);
      }
      document.getElementById('linkajaform').submit();
  } catch (e) {
      setError(true);
      dispatch(setLoadingState({ loading: false }));
  }
};

export default LinkAjaWco;