// if a spesific payment partner is also mention in recommendationPayment return false, else return true
export function compareToRecommendation(listRecommendationPayment, paymentPartnerDisplayName) {
    if (listRecommendationPayment.length !== 0) {
        //looping to compare display name all recommendation payment with a spesific payment partner
        for (const element of listRecommendationPayment) {
            if (paymentPartnerDisplayName === element.display_name) {
                return false;
            }
        }
        return true;
    } else {
        return true;
    }
}
