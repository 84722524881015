import React from 'react';
import { Header, ModalAlert } from 'presentations/shared';
import { submitOrder } from 'core/controller';
import VoucherPage from '../voucher-page';
import { ModalBindingOffer, ModalUnbind, ModalOTP } from './Components';
import ModalSelectPayment from '../voucher-page/Components/ModalSelectPayment';
import WarningLogo from '../../../presentations/assets/images/warning.png';
import NotFound from '../../../presentations/assets/images/not_found.png';
import ModalInquiry from './Components/ModalInquiry';
import RequestOtpPage from '../otp-page';
import { useSelector } from 'react-redux';

export const NoPartnerSelected = (props) => {
    const stateGlobal = useSelector((state) => state);
    const { showModalAlert, setShowModalAlert, t } = props;
    if (showModalAlert) {
        // return <ModalAlert text={t('not_selected')} text_button="OK" onClick={() => setShowModalAlert(false)} />;
        return (
            <ModalAlert
                img={WarningLogo}
                text={t('not_selected')}
                text_button="OK"
                onClick={() => setShowModalAlert(false)}
            />
        );
    }
    return null;
};

export const UnbindSuccess = (props) => {
    const { showUnBindSuccess, setShowUnBindSuccess, t } = props;
    if (showUnBindSuccess) {
        return (
            <ModalAlert
                img={NotFound}
                title_text={t('delete_success')}
                text={t('delete_success_message')}
                text_button={t('done')}
                onClick={() => setShowUnBindSuccess(false)}
            />
        );
    }
    return null;
};

export const GeneralError = (props) => {
    const { showModalAlertError, setShowModalAlertError, errorModalMessage, t } = props;

    if (showModalAlertError) {
        return <ModalAlert text={t(errorModalMessage || 'failed_3')} text_button="OK" onClick={() => setShowModalAlertError(false)} />;
    }
    return null;
};

export const BalanceAlert = (props) => {
    const { showBalanceAlert, setShowBalanceAlert, selectedValue, t } = props;
    if (showBalanceAlert) {
        return (
            <ModalAlert
                text={t('insufficient_balance')}
                text_button={t('other_payment_method')}
                onClick={() => setShowBalanceAlert(false)}
                isBalance={selectedValue === 'balance'}
            />
        );
    }
    return null;
};

export const BindingModal = (props) => {
    const {
        showModalOffer,
        selectedValue,
        setShowModalOffer,
        onBind,
        bodytoElisa,
        externalPayment,
        token,
        stateGlobal,
        navigate,
    } = props;

    if (showModalOffer) {
        return (
            <ModalBindingOffer
                partner={selectedValue}
                onClose={() => setShowModalOffer(false)}
                onContinue={() =>
                    submitOrder(
                        bodytoElisa,
                        externalPayment,
                        token,
                        stateGlobal.upp_data.total_price,
                        navigate,
                        '',
                        '',
                        stateGlobal.upp_data.partner_price
                    )
                }
                onBind={onBind}
            />
        );
    }
    return null;
};

export const OTPModal = (props) => {
    const { showModalOTP, setShowModalOTP, setOTPCode, submitOrdertoElisa } = props;
    if (showModalOTP) {
        return (
            <ModalOTP
                onClose={() => setShowModalOTP(false)}
                submitOTP={(e) => {
                    setOTPCode(e);
                }}
                resendOTP={submitOrdertoElisa}
            />
        );
    }
    return null;
};

export const RequestOtp = (props) => {
    const { showOTP, setShowOTP, setOtp, submitReqOtp } = props;
    if (showOTP) {
        return (
            <RequestOtpPage
                onClose={() => setShowOTP(false)}
                submitOtp={(e) => {
                    submitReqOtp(e);
                }}
                // resendOTP={submitOrdertoElisa}
            />
        );
    }
    return null;
};

export const UnbindingModal = (props) => {
    const { showModalUnbind, selectedValue, setShowModalUnbind, onUnbind, onSuccessUnbind } = props;
    if (showModalUnbind) {
        return (
            <ModalUnbind
                partner={selectedValue}
                onClick={(() => setShowModalUnbind(false), onSuccessUnbind)}
                onClose={() => setShowModalUnbind(false)}
                onUnbind={onUnbind}
            />
        );
    }
    return null;
};

export const InquiryModal = (props) => {
    const { showModalInquiry, selectInquiry, setSelectInquiry, setShowModalInquiry, dataInquiry, reqOtp } = props;

    if (showModalInquiry) {
        return (
            <ModalInquiry
                selectInquiry={selectInquiry}
                onChangeIquiry={setSelectInquiry}
                reqOtp={reqOtp}
                onClose={() => setShowModalInquiry(false)}
                dataInquiry={dataInquiry}
            />
        );
    }
    return null;
};

export const ShowHeader = (props) => {
    const { stateGlobal } = props;
    if (stateGlobal.upp_data.channel !== '') {
        return <Header channel={stateGlobal.upp_data.channel} />;
    }
    return null;
};

export const PromoPage = (props) => {
    const { showPromo, selectPromo, setShowPromo, bodytoElisa, setShowModalOffer, handleChange } = props;
    if (showPromo) {
        return (
            <VoucherPage
                show={showPromo}
                selectPromo={selectPromo}
                navigate={setShowPromo}
                body={bodytoElisa}
                showBind={setShowModalOffer}
                changePayment={handleChange}
            />
        );
    }
    return null;
};

export const PaymentModal = (props) => {
    const { showModalPayment, showPromo, setModalPayment, setSelectedPaymentPromo } = props;
    if (showModalPayment && showPromo) {
        return (
            <ModalSelectPayment
                onClose={() => setModalPayment(false)}
                onContinue={() => setSelectedPaymentPromo('gopay|')}
                payment="Gopay"
            />
        );
    }
    return null;
};
