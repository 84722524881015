import { price } from 'utilities';
import React from 'react';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Collapse from '@mui/material/Collapse';
import IconBill from 'presentations/assets/images/icon_bill.svg';
import IconVoucher from 'presentations/assets/images/icons/icon_voucher.svg';
import ToppingDetail from 'presentations/shared/ToppingDetail';
import { ProductDetail } from 'presentations/shared';
import IconVoucherApplied from 'presentations/assets/images/icons/icon_voucher_applied.svg';

function PaymentTotal({
    totalPayment,
    productPrice,
    handleNavigate,
    handleVoucher,
    selectedPromo,
    textButton,
    textPromoButton,
    word,
    isExpand,
    setExpand,
    product,
    admin_fee_wording,
    promo_wording,
    payment_details,
    order_details,
    admin_fee_value,
    platform_fee_value,
    activePromo,
    item_detail,
    type,
    platform_fee_wording,
}) {
    const totalPrice = (discount = 0) => {
        return price(productPrice + admin_fee_value + platform_fee_value - discount);
    };

    const ToppingDetailsMobile = ({ item }) => {
        return (
            <div className="flex justify-between w-full mt-2 px-4">
                <p className={'text-total font-face-poppinsregular'}>{item.topping_name}</p>
            </div>
        );
    };
    return (
        <div
            className="bg-white min-h-36 lg:static md:static shadow-lg fixed bottom-0 left-0 w-full flex flex-wrap content-center pt-2 pb-5 lg:rounded-xl md:rounded-xl rounded-t-xl items-center border-t-2 lg:border-t-0 md:border-t-0 lg:mt-3 md:mt-3"
            onClick={setExpand}
        >
            <div className="hidden lg:block md:block w-full">
                <div className="flex w-full px-4 mt-2">
                    <img src={IconBill} alt="Payment" className="mr-2" />
                    <p className="font-face-tselbold">{order_details}</p>
                </div>
                {item_detail && <ProductDetail items={item_detail} />}
                {item_detail?.topping_details &&
                    item_detail?.topping_details?.map((item, index) => {
                        return <ToppingDetail key={index} items={item} />;
                    })}
            </div>
            {activePromo && (
                <div className="w-full px-4 my-2 lg:my-5 md:my-5">
                    <button
                        className={`flex justify-between rounded-xl w-full my-2 h-1/2 p-2 shadow-lg
                        ${selectedPromo?.promo === '' ? `bg-white` : `bg-green-voucher_applied`}`}
                        onClick={handleVoucher}
                    >
                        <div className="flex">
                            <img
                                src={selectedPromo?.promo !== '' ? IconVoucherApplied : IconVoucher}
                                alt="Voucher"
                                className="mx-2"
                            />
                            <p className="text-blue-dark_blue text-button font-face-poppinsbold self-center">
                                {selectedPromo?.description !== '' ? selectedPromo?.description : textPromoButton}
                            </p>
                        </div>
                    </button>
                </div>
            )}
            <Collapse in={isExpand} className="w-full">
                <div className="flex w-full px-4 mt-2">
                    <img src={IconBill} alt="Payment" className="mr-2" />
                    <p className="font-face-tselbold">{payment_details}</p>
                </div>
                <div className="flex justify-between w-full mt-3 px-4">
                    <p className={'text-total font-face-poppinsregular'}>{product}</p>
                    <p className="font-face-poppinsregular text-total">Rp{price(productPrice)}</p>
                </div>
                <div className="lg:hidden md:hidden">
                    {item_detail?.topping_details &&
                        item_detail?.topping_details?.map((item, index) => {
                            return <ToppingDetailsMobile key={index} item={item} />;
                        })}
                </div>
                {admin_fee_value !== 0 && (
                    <div className="flex justify-between w-full mt-3 px-4">
                        <p className="text-total font-face-poppinsregular">{admin_fee_wording}</p>
                        <p className="font-face-poppinsregular text-total">
                            Rp
                            {price(admin_fee_value)}
                        </p>
                    </div>
                )}
                {platform_fee_value !== 0 && (
                    <div className="flex justify-between w-full mt-3 px-4">
                        <p className="text-total font-face-poppinsregular">{platform_fee_wording}</p>
                        <p className="font-face-poppinsregular text-total">
                            Rp
                            {price(platform_fee_value)}
                        </p>
                    </div>
                )}
                {selectedPromo
                    ? selectedPromo?.promo !== '' && (
                          <div className="flex justify-between w-full mt-3 px-4">
                              <p className="text-total font-face-poppinsregular">
                                  {selectedPromo?.promo?.additional_info?.type === 'CASHBACK'
                                      ? promo_wording.cashback
                                      : promo_wording.discount}
                              </p>
                              <p className="font-face-poppinsregular text-total">
                                  Rp{price(selectedPromo?.promo?.promotion_amount?.split('.')[0])}
                              </p>
                          </div>
                      )
                    : null}
            </Collapse>
            <div className="flex justify-between w-full mt-2 lg:mt-4 md:mt-4 px-4 border-t-2 pt-4 border-none md:border-dashed lg:border-dashed">
                <p className="text-total font-face-poppinsregular">{word}</p>
                    <p className="font-face-poppinsbold text-total">
                        Rp
                        {selectedPromo?.promo?.additional_info?.type === 'CASHBACK'
                            ? totalPrice()
                            : totalPrice(selectedPromo?.promo?.promotion_amount?.split('.')[0])}
                </p>
            </div>
            <div className={`w-full px-4 ${type === 'credit_card' ? '' : 'lg:hidden md:hidden'}`}>
                <button
                    className="rounded-3xl w-full bg-red-tsel mt-4 h-1/2 p-4 hover:bg-red-500"
                    onClick={handleNavigate}
                >
                    <p className="text-white text-button font-face-poppinsbold">{textButton}</p>
                </button>
            </div>
        </div>
    );
}

export default PaymentTotal;
