import { React } from 'react';

export const AccountNotFoundNotification = (props) => {
    const { showError, t } = props;
    return (
        <>
            {showError ? (
                <p className="text-red-tsel_dark text-number_gopay font-face-poppinsregular">
                    {t('gopay_account_not_found')}
                </p>
            ) : null}
        </>
    );
};
