import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_MINUTE, DEFAULT_SECOND, DEFAULT_HOUR } from 'utilities';
import 'utilities/i18n';
import { useDispatch } from 'react-redux';
import { setLoadingState } from 'adapters';
import getStaticInstructionPayment from 'domain/use-case/payment/getStaticInstructionPayment';

export default function QRISPageViewModel() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { state } = useLocation();
    const { qris_url, detail, total, payment_info, channel, redirect, help, failed } = state;
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();
    const [showModalShare, setShowModalShare] = useState(false);
    const [token, setToken] = useState();
    const [instructions, setInstructions] = useState();


    const goToPreviousPath = () => {
        navigate(-2);
    };

    // calculate remaining time based on expiry duration and duration unit
    useEffect(() => {
        dispatch(setLoadingState({ loading: false }));
        switch (payment_info.duration_unit) {
            case 'second':
                let hour_in_second = Math.floor(payment_info.expiry_duration / 3600);
                let minute_in_second = Math.floor((payment_info.expiry_duration % 3600) / 60);
                let second_in_second = payment_info.expiry_duration - hour_in_second * 3600 - minute_in_second * 60;
                setHours(hour_in_second);
                setMinutes(minute_in_second);
                setSeconds(second_in_second);
                break;
            case 'minute':
                let hour_in_minute = Math.floor(payment_info.expiry_duration / 60);
                let minute_in_minute = Math.floor(payment_info.expiry_duration % 60);
                let second_in_minute = 0;
                setHours(hour_in_minute);
                setMinutes(minute_in_minute);
                setSeconds(second_in_minute);
                break;
            case 'hour':
                let hour_in_hour = payment_info.expiry_duration;
                let minute_in_hour = 0;
                let second_in_hour = 0;
                setHours(hour_in_hour);
                setMinutes(minute_in_hour);
                setSeconds(second_in_hour);
                break;
            case 'day':
                let hour_in_day = payment_info.expiry_duration * 24;
                let minute_in_day = 0;
                let second_in_day = 0;
                setHours(hour_in_day);
                setMinutes(minute_in_day);
                setSeconds(second_in_day);
                break;
            default:
                setHours(DEFAULT_HOUR);
                setMinutes(DEFAULT_MINUTE);
                setSeconds(DEFAULT_SECOND);
        }
        // eslint-disable-next-line
    }, []);

    const countHourOnly = useCallback(
        (interval) => {
            if (hours === 0) {
                clearInterval(interval);
            } else {
                setHours(hours - 1);
            }
        },
        [hours]
    );

    const countHourMinutesSeconds = useCallback(
        (interval) => {
            if (minutes === 0) {
                setMinutes(59);
                countHourOnly(interval);
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        },
        [countHourOnly, minutes]
    );

    const countDown = useCallback(
        (interval) => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else if (seconds === 0) {
                countHourMinutesSeconds(interval);
            }
        },
        [countHourMinutesSeconds, seconds]
    );

    // perform countdown
    useEffect(() => {
        let myInterval = setInterval(() => {
            countDown(myInterval);
            // redirect to failed page if countdown finished
            if (hours === 0 && minutes === 0 && seconds === 0) {
                window.location.replace(failed);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [countDown, failed, hours, minutes, seconds]);

    const getInstructions = async (token) => {
        const payload = {
            partner_code: 'qris',
            partner_agg_code: 'gopay',
        }
        try {
            const response = await getStaticInstructionPayment(payload,token);
            if (response && response.status_code === '00') {
                setInstructions(response);
            }
        } catch (e) {
            console.error(e);
        }
    }

    return {
        goToPreviousPath,
        qris_url,
        hours,
        minutes,
        seconds,
        total,
        detail,
        help,
        redirect,
        channel,
        token,
        setToken,
        instructions,
        getInstructions,
        showModalShare,
        setShowModalShare,
    };
}
