import React from 'react';
import DOMPurify from 'dompurify';

export default function HelpSection({ url }) {
    const sanitizer = DOMPurify.sanitize;
    if (url !== '') {
        return <div className="Help" dangerouslySetInnerHTML={{ __html: sanitizer(url) }} />;
    } else {
        return <div className="bg-gray-200 h-8" data-testid="empty-div" />;
    }
}
