import React, { useState } from 'react';

const RequestOtpPage = ({ submitOtp, onClose }) => {
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpCode, setOtpCode] = useState('');

    const isAllEmpty = otp.some((element) => element === '');
    const changeHandler = (index, value) => {
        const sanitizedValue = value.replace(/\D/g, '').slice(0, 1);
        const newOTPValues = [...otp];
        newOTPValues[index] = sanitizedValue;
        setOtp(newOTPValues);
        setOtpCode(newOTPValues.join().replace(/,/g, ''));
        if (sanitizedValue && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="opacity-75 fixed inset-0 z-51 bg-blue-dark_blue" onClick={onClose}></div>
                <div className="relative w-full mx-auto max-w-3xl px-8 lg:px-32 z-52">
                    <div className="border-0 rounded-lg shadow-lg relative px-4 flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="relative flex-auto w-full pt-10 pb-6">
                            <p className="font-face-tselbold mx-auto text-center text-topbar">
                                Masukkan Kode Verifikasi
                            </p>
                            <p className="text-base font-face-poppinslight text-center">
                                Kode verifikasi 4 digit telah dikirim ke nomor handphone
                            </p>
                            <br></br>
                            <p className="text-base font-face-poppinslight-2 text-center">Kode verifikasi</p>
                            <br></br>
                            <div className="otp-group mx-auto">
                                {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        id={`otp-input-${index}`}
                                        type="text"
                                        inputMode="numeric"
                                        autoComplete="one-time-code"
                                        pattern="\d{1}"
                                        maxLength={2}
                                        className="otp-input"
                                        value={digit}
                                        onChange={(e) => changeHandler(index, e.target.value)}
                                    />
                                ))}
                            </div>
                            <br></br>

                            {/* <p className="text-base font-face-poppinslight-2 text-red-error text-center">
                                {otpWarning !== '' ? otpWarning : ''}
                            </p> */}

                            {/* <p className=" text-payment_code_title font-face-poppinsregular text-center">
                                {t('OTP_verification_word_3')}
                                {minutes === 0 && seconds === 0 ? (
                                    <span className=" font-bold" onClick={handleResendOTP}>
                                        Kirim Ulang
                                    </span>
                                ) : (
                                    <span className="font-bold ">
                                        {t('OTP_verification_word_4')}
                                        {minutes < 10 ? `0${minutes}` : minutes} :{' '}
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                    </span>
                                )}
                            </p> */}
                        </div>
                        {/*footer*/}
                        <div className="flex items-center p-6 flex-col">
                            <button
                                className="bg-red-tsel w-full text-white active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                disabled={isAllEmpty ? true : false}
                                onClick={() => {
                                    submitOtp(otpCode);
                                }}
                            >
                                {'Kirim'}
                            </button>
                            {/* <BuyPackageButton
                                t={t}
                                isBalance={isBalance}
                                redirectToPurchaseBalance={redirectToPurchaseBalance}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RequestOtpPage;
