import { setLoadingState } from 'adapters';
import { CREDIT_CARD } from 'utilities';
import { redirectPayment } from 'core/controller';
import { setPricing } from '../setPricing';
import { submitPaymentToElisa } from 'domain/use-case';
import LinkAjaWco from './LinkAjaWco';

const DirectSubmit = async (body, auth, totalPay, dispatch, navigate, setError, stateGlobal, state) => {
    const { setLinkajaAuth, setLinkajaUrl, setIsLinkAjaWco } = state;
    dispatch(setLoadingState({ loading: true }));
    if (body.payment_info.payment_provider === CREDIT_CARD) {
        dispatch(setLoadingState({ loading: true }));
        navigate('/creditcardform', {
            state: {
                detail: body.item_details,
                payment_info: stateGlobal.upp_data.payment_info,
                app: body.channel,
                body: body,
                total: totalPay,
                redirect: body.redirect_url,
                failed: body.redirect_url_failed,
                token: stateGlobal.upp_data.token,
            },
        });
    } else {
        dispatch(setLoadingState({ loading: true }));
        // call updatePayment to Elisa
        await submitPaymentToElisa(body.payment_info, auth)
            .then((response) => {
                if (response) {
                    if (response.partner_type === 4) {
                        // perform post to linkaja webcheckout
                        LinkAjaWco(
                            response.content,
                            response.payment_url,
                            dispatch,
                            { setLinkajaAuth, setLinkajaUrl, setIsLinkAjaWco },
                            setError
                        );
                    } else {
                        // redirect to url or page based on response from Elisa
                        redirectPayment(
                            response,
                            body,
                            navigate,
                            setPricing(
                                stateGlobal.upp_data.payment_info.payment_provider,
                                body,
                                body.additional_external_payment,
                                body.list_payment_partner
                            )
                        );
                    }
                }
            })
            .catch((e) => {
                setError(true);
                // dispatch(setLoadingState({ loading: false }));
            });
    }
};

export default DirectSubmit;
