// price formatter
export const price = (num) => {
    let num_parts = num.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return num_parts.join('.');
};

// remove dots and convert to integer
export const stringToPrice = (str) => {
    return parseInt(str.replace(/\./g, ''), 10);
};
