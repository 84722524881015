import { setLoadingState } from "adapters";

 // perform post form to mandiri webcheckout for binding
 const MandiriDDWCO = async (res, dispatch, state) => {
  const { setMandiriDDRes, setIsMandiriDDWco, setShowModalAlertError } = state;
  dispatch(setLoadingState({ loading: true }));
  try {
      if (res) {
          setMandiriDDRes(res.payment_response);
          setIsMandiriDDWco(true);
      }
      document.getElementById('frm_request').submit();
      dispatch(setLoadingState({ loading: false }));
  } catch (err) {
      setShowModalAlertError(true);
  }
};

export default MandiriDDWCO;