import { requestOtpCode } from 'domain/use-case';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BNI_DD } from 'utilities';
import Connected from '../../../presentations/assets/images/success_hand.png';
import WarningLogo from '../../../presentations/assets/images/warning.png';
import FailedLogo from '../../../presentations/assets/images/failed_net.png';

export default function BindingDDViewModel() {
    const { state } = useLocation();
    const { payment_info, customer_info } = state;
    const stateGlobal = useSelector((states) => states.upp_data);
    let navigate = useNavigate();
    const { partner } = useParams();
    const [modalWording, setModalWording] = useState({
        modalText: '',
        modalTitle: '',
        modalButton: '',
        modalImg: '',
        modalOnClick: '',
    });
    const [dataDirectDebit, setDataDirectDebit] = useState({
        card_number: '',
        account_no: '',
    });
    // const [agree, setAgree] = useState(false);
    const [close, setClose] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showModalAlert, setShowModalAlert] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [otpWarning, setOtpWarning] = useState('');

    const goToPreviousPath = () => {
        navigate(-1);
    };

    const handletoLP = () => {
        setShowModalAlert(false);
        if (close) {
            navigate(-1);
        }
    };

    const initiateBinding = async (value) => {
        if (payment_info.payment_provider === BNI_DD) {
            reqOtp(payment_info.payment_provider);
        }
    };

    const handleOtp = (alertValue, word) => {
        if (word === '') {
            setShowModalAlert(true);
            setOtpWarning('');
            setModalWording({
                ...setModalWording,
                modalText: 'connected_bni',
                modalTitle: 'connected_word',
                modalButton: 'done',
                modalImg: Connected,
            });
            setClose(true);
        } else if (word.includes('Invalid Token')) {
            setShowModalAlert(true);
            setOtpWarning('');
            setModalWording({
                ...setModalWording,
                modalText: 'failed_wording',
                modalTitle: 'failed',
                modalButton: 're_input',
                modalImg: FailedLogo,
            });
        } else if (word.includes('Timeout to Worker')) {
            setShowModalAlert(true);
            setOtpWarning('');
            setModalWording({
                ...setModalWording,
                modalText: 'failed_wording',
                modalTitle: 'failed',
                modalButton: 're_input',
                modalImg: FailedLogo,
            });
        } else if (word.includes('Limit Binding Attempt')) {
            setShowModalAlert(true);
            setOtpWarning('');
            setModalWording({
                ...setModalWording,
                modalText: 'failed_limit',
                modalTitle: 'failed',
                modalButton: 're_input',
                modalImg: FailedLogo,
            });
            setShowOtp(false);
        } else if (alertValue !== true) {
            setOtpWarning(word);
        }

        if (alertValue === false) {
            setShowOtp(true);
        }
    };

    const reqOtp = async (partner) => {
        const binding_body = {
            payment_provider: partner,
            card_number: dataDirectDebit.card_number,
            account_no: dataDirectDebit.account_no,
        };
        if (partner === BNI_DD) {
            try {
                // directly hit binding API for BNI direct debit
                const response = await requestOtpCode(binding_body, stateGlobal.token);
                if (response && response.status_code === '00') {
                    setShowOtp(true);
                    setModalWording({
                        ...setModalWording,
                        modalText: 'connected_bni',
                        modalTitle: 'connected_word',
                        modalButton: 'done',
                        modalImg: Connected,
                    });
                } else {
                    if (
                        response.data.payment_response.response_desc === 'Card Information Invalid' ||
                        response.data.status_desc.includes('Gateway Error') ||
                        response.data.status_desc.includes('Invalid Token')
                    ) {
                        setShowModalAlert(true);
                        setModalWording({
                            ...setModalWording,
                            modalText: 'warningdata',
                            modalTitle: 'invalid_data',
                            modalButton: 'check_data',
                            modalImg: WarningLogo,
                        });
                    } else if (response.data.payment_response.response_desc === 'Too Many Requests') {
                        setShowModalAlert(true);
                        setModalWording({
                            ...setModalWording,
                            modalText: 'three_times_wording',
                            modalTitle: 'three_times',
                            modalButton: 'wait',
                            modalImg: WarningLogo,
                        });
                    }
                }
            } catch (err) {
                console.log(err);
                // setShowModalAlertError(true);
            }
        }
    };

    return {
        payment_info,
        customer_info,
        partner,
        stateGlobal,
        setDataDirectDebit,
        dataDirectDebit,
        goToPreviousPath,
        initiateBinding,
        showError,
        showOtp,
        setShowOtp,
        setShowError,
        showModalAlert,
        setShowModalAlert,
        modalWording,
        handleOtp,
        handletoLP,
        otpWarning,
        setModalWording,
    };
}
