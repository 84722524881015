import React from 'react';
import { HeaderLogo } from './ComponentPage';
import LinkLogo from './../assets/images/Link.png';

export default function PartnerHeader({ channel, partnerlogo }) {
    let channelLogo = `${process.env.REACT_APP_IMAGE_HOST}/public/logo/${channel}.png`;
    if (channel?.includes('http')) {
        channelLogo = channel;
    }
    return (
        <div className="flex flex-row mt-4 h-10 bg-white">
            <div className="flex w-3/4 h-10 lg:max-h-20 content-center items-center place-content-center">
                <img
                    src={channelLogo}
                    alt="Logo"
                    className="max-h-7 lg:max-h-7"
                />
            </div>
            <div className="flex w-1/2 h-10 lg:max-h-20 content-center items-center place-content-center">
                <img src={LinkLogo} alt="Link" className="max-h-10 lg:max-h-10" />
            </div>
            <div className="flex w-1/2 h-10 content-center items-center place-content-center ">
                <HeaderLogo partnerlogo={partnerlogo} />
            </div>
        </div>
    );
}
