import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkExpiredMonthAndYear, transformURLPartner } from 'utilities';
import { submitPaymentToElisa } from 'domain/use-case';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingState } from 'adapters';


export default function CreditCardPageViewModel() {
    const dispatch = useDispatch();
    const stateGlobal = useSelector((state) => state);
    const [check, setCheck] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [cardName, setCardName] = useState('');
    const [cardCvv, setCardCvv] = useState('');
    const [cardMonth, setCardMonth] = useState('');
    const [cardYear, setCardYear] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const [showModalAlertError, setShowModalAlertError] = useState(false);
    let navigate = useNavigate();
    const { state } = useLocation();
    const { detail, payment_info, app, body, total, token } = state;

    const goToPreviousPath = () => {
        navigate(-2);
    };

    const picoModal = window.picoModal;
    const midtrans = window.MidtransNew3ds;

    // redirect options based on midtrans transaction status
    const optionsRedirect = {
        performAuthentication: function (redirect_url) {
            // Implement how you will open iframe to display 3ds authentication redirect_url to customer
            popupModal.openPopup(redirect_url);
        },
        onSuccess: function () {
            // 3ds authentication success, implement payment success scenario
            popupModal.closePopup();
            setTimeout(() => {
                window.location.replace(body.redirect_url);
            }, 3000);
        },
        onFailure: function () {
            // 3ds authentication failure, implement payment failure scenario
            popupModal.closePopup();
            setTimeout(() => {
                window.location.replace(body.redirect_url_failed);
            }, 3000);
        },
        onPending: function () {
            // transaction is pending, transaction result will be notified later via
            // HTTP POST notification, implement as you wish here
            popupModal.closePopup();
        },
    };

    // submit order to midtrans with defined payment_id for direct upp_type
    const directSubmitToMidtrans = async () => {
        dispatch(setLoadingState({ loading: true }));
        try {
            const response = await submitPaymentToElisa(payment_info, token);
            if (response) {
                midtrans.authenticate(response.payment_response.checkout_url, optionsRedirect);
            } else {
                setShowModalAlertError(true);
            }
        } catch (e) {
            setError(true);
            dispatch(setLoadingState({ loading: false }));
        }
    };

    useEffect(() => {
        // handling direct type of credit_card payment
        if (body.upp_type === 'direct') {
            dispatch(setLoadingState({ loading: true }));
            directSubmitToMidtrans();
        } else {
            dispatch(setLoadingState({ loading: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // midtrans modal
    const popupModal = (function () {
        let modal = null;
        return {
            openPopup(url) {
                modal = picoModal({
                    content: '<iframe frameborder="0" style="height:90vh; width:100%;" src="' + url + '"></iframe>',
                    width: '100%',
                    height: '100%',
                    closeButton: false,
                    overlayClose: false,
                    escCloses: false,
                }).show();
            },
            closePopup() {
                try {
                    modal.close();
                } catch (e) {}
            },
        };
    })();

    // performed after get token for midtrans
    const options = {
        onSuccess: async function (response) {
            // Implement success handling here
            if (response) {
                if (body.upp_type === 'indirect_with_order' && body.external_channel === false) {
                    window.location.assign(transformURLPartner(body, null, null, response.token_id,stateGlobal.upp_data.total_price,stateGlobal.upp_data.partner_price));
                } else {
                    dispatch(setLoadingState({ loading: true }));
                    payment_info.payment_id = response.token_id;
                    try {
                        const res = await submitPaymentToElisa(payment_info, token);
                        if (res) {
                            midtrans.authenticate(res.url_payment, optionsRedirect);
                        } else {
                            setShowModalAlertError(true);
                        }
                    } catch (e) {
                        setError(true);
                        dispatch(setLoadingState({ loading: false }));
                    }
                }
            } else {
                window.location.replace(body.redirect_url_failed);
            }
        },
        onFailure: function (response) {
            // Implement error handling here
            if (response && response !== undefined && response.status_code === '200') {
                setErrorMessage(response.validation_messages);
            } else {
                window.location.replace(body.redirect_url_failed);
            }
        },
    };

    let card = {
        card_number: cardNumber,
        card_cvv: cardCvv,
        card_exp_month: cardMonth,
        card_exp_year: cardYear,
    };

    const submitToMidtrans = async () => {
        let checkPayload =
            cardNumber.length < 19 ||
            cardCvv.length < 3 ||
            cardName.length === 0 ||
            cardMonth.length === 0 ||
            cardYear.length < 4 ||
            checkExpiredMonthAndYear(cardMonth, cardYear);
        // credit card data validation, and show error message
        if (checkPayload) {
            setCheck(true);
        } else {
            dispatch(setLoadingState({ loading: true }));
            // get token from midtrans
            midtrans.getCardToken(card, options);
        }
    };

    return {
        error,
        app,
        goToPreviousPath,
        detail,
        total,
        submitToMidtrans,
        check,
        cardNumber,
        cardName,
        cardCvv,
        cardMonth,
        cardYear,
        setCardNumber,
        setCardName,
        setCardCvv,
        setCardMonth,
        setCardYear,
        errorMessage,
        showModalAlertError,
        setShowModalAlertError,
    };
}
