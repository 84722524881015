import React, { useEffect } from 'react';
import { getToken, price } from 'utilities';
import {
    DownloadQRISInstruction,
    Header,
    HelpSection,
    ProductDetail,
    QRISPaymentPartner,
    QRISSection,
    ScanQRISInstruction,
    TopBar,
    PaymentCountDown,
} from 'presentations/shared';
import { useTranslation } from 'react-i18next';
import 'utilities/i18n';
import useViewModel from './QRISPageViewModel';
import { ModalShare } from './Components';

// QRIS Page
export default function QRISPage() {
    const { t } = useTranslation();
    const {
        goToPreviousPath,
        qris_url,
        hours,
        minutes,
        seconds,
        total,
        detail,
        help,
        redirect,
        channel,
        showModalShare,
        setShowModalShare,
        setToken,
        token,
        getInstructions,
        instructions
    } = useViewModel();

    useEffect(() => {
        setToken(getToken);
        if (token) {
            getInstructions(token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return (
        <>
            <div className="flex-1 h-screen md:h-screen lg:h-full bg-gray-200 w-full">
                <div className="h-full md:h-screen lg:h-full w-full pt-16.5 bg-gray-200 lg:px-98">
                    <TopBar text={t('qris_title')} onClick={goToPreviousPath} />
                    <QRISSection
                        url={qris_url}
                        onClick={() => {
                            setShowModalShare(true);
                        }}
                    />
                    <div className="mt-3">
                        <PaymentCountDown
                            hours={hours}
                            minutes={minutes}
                            seconds={seconds}
                            text={t('complete_the_payment')}
                        />
                    </div>
                    <QRISPaymentPartner text={t('pay_with')} />
                    <div className="h-auto w-full px-4 py-4 justify-between flex flex-row mt-3 bg-white border-b-2 items-center">
                        <p className="text-xs font-face-poppinsregular text-gray-hero_title">{t('total')}</p>
                        <p className="text-xs font-face-poppinsbold text-gray-hero_title">Rp {price(total)}</p>
                    </div>
                    <div className="-mt-2 lg:-mt-3">
                        <ProductDetail items={detail} />
                    </div>
                    {/* <div className="">
                        <DownloadQRISInstruction
                            text={t('how_to_download_qris')}
                            show={showDownloadInstruction}
                            handleCollapse={() => handleCollapseDownloadInstruction()}
                        />
                    </div>
                    <div className="h-3 bg-gray-200"></div> */}
                   {instructions?.data?.map((instruction) => {
                        return (
                            <div className="">
                            <ScanQRISInstruction
                                instruction={instruction}
                            />
                        </div>
                        )
                    })
                   }
                    <HelpSection url={help} />
                    <div className="bg-white h-auto w-full py-5 px-8 lg:px-20 ">
                        <button
                            type="submit"
                            className="rounded-3xl w-full mx-auto flex bg-red-tsel py-3 lg:py-5 px-5 hover:bg-red-500"
                            onClick={() => {
                                window.location.replace(redirect);
                            }}
                        >
                            <p className="text-white font-face-poppinsbold mx-auto text-button">{t('already_paid')}</p>
                        </button>
                    </div>
                    <Header channel={channel} />
                </div>
            </div>
            {showModalShare && (
                <ModalShare
                    onClose={() => {
                        setShowModalShare(false);
                    }}
                />
            )}
        </>
    );
}
