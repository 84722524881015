import React from 'react';
import { ALFAMART, INDOMARET } from 'utilities';
import PaymentInstruction from './PaymentInstruction';
import VirtualAccountInstruction from './VirtualAccountInstruction';

const isOfflineMerchant = (payment_info) => {
    return payment_info.payment_provider === ALFAMART || payment_info.payment_provider === INDOMARET;
};

export const Instruction = ({ payment_info, partner, instructions }) => {
    if (instructions){
        if (isOfflineMerchant(payment_info)) {
            return <PaymentInstruction name={partner} code={payment_info.payment_code} instructions={instructions} />;
        } else {
            return instructions?.data?.map((instruction) => {
                return <VirtualAccountInstruction instruction={instruction} />;
            });
        }
    } else {
        return null;
    }
};
