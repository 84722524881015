import { isStringValid } from './isStringValid';
import { checkFormatPhone, phoneNumber as phoneNumberFormatter } from './phoneNumber';

export const transformURLPartner = (inputBody, phoneNumber, bank, paymentId, totalPrice, partnerPrice) => {
    let result = handleBody(inputBody, inputBody.item_details.item_price, inputBody.payment_info).price;
    // let itemPrice = result.itemPrice ? result.itemPrice : result;
    let url = result.itemPrice ? result.url : '';

    let urlTransformed =
        `${inputBody.redirect_url_payment}/?partner_provider=${inputBody.payment_info.payment_provider}&transaction_id=${inputBody.transaction_id}&item_price=${totalPrice}` +
        url;

    // let urlTransformed =
    // `${inputBody.redirect_url_payment}/?partner_provider=${inputBody.payment_info.payment_provider}&transaction_id=${inputBody.transaction_id}&item_price=${itemPrice}` +
    // url;

    if (isStringValid(bank)) urlTransformed += `&bank_name=${bank}`;
    if (isStringValid(phoneNumber)) {
        if (checkFormatPhone(phoneNumber)) {
            urlTransformed += `&payment_id=${phoneNumberFormatter(phoneNumber)}`;
        } else {
            urlTransformed += `&payment_id=62${phoneNumber}`;
        }
    }

    if (isStringValid(paymentId)) urlTransformed += `&payment_id=${paymentId}`;

    return urlTransformed;
};

export const handleBody = (inputBody, totalPrice, payment_info) => {
    let price = '';

    const selectedPayment = inputBody.list_payment_partner.filter(
        (item) =>
            item.partner_provider === payment_info.payment_provider &&
            (item.bank_name === payment_info.bank_name ||
                'binding' === payment_info.bank_name ||
                '' === payment_info.bank_name ||
                'gopay' === payment_info.bank_name)
    );

    if (selectedPayment.length > 0) {
        console.log('masuk');
        price = itemPriceHandle(selectedPayment[0], totalPrice);
    } else if (
        inputBody.additional_external_payment.filter((item) => item.partner_provider === payment_info.payment_provider)
            .length > 0
    ) {
        price = totalPrice;
    }

    return { price };
};

export const itemPriceHandle = (inputBody, totalPrice) => {
    let itemFee = 0;
    let platformFee = 0;
    let url = '';
    if (inputBody.item_fee !== 0) {
        url += `&item_fee=${inputBody.item_fee}`;
        itemFee = inputBody.item_fee;
    }
    if (inputBody.platform_fee !== 0) {
        url += `&platform_fee=${inputBody.platform_fee}`;
        platformFee = inputBody.platform_fee;
    }
    const itemPrice = itemFee + platformFee + totalPrice;

    return { itemPrice, url };
};

export const shareUrl = (token) => {
    let baseUrl = `https://payment.telkomsel.com/paymentCode`;
    if (isStringValid(token)) {
        baseUrl += `?session_id=${token}`;
    }
    return baseUrl;
};
