import React, { useEffect, useState } from 'react';
import Gopay from 'presentations/assets/images/gopay-rounded.png';
import Ribbon from 'presentations/assets/images/icons/ribbon.svg';
import RibbonGray from 'presentations/assets/images/icons/ribbon_gray.svg';
import SuccessIcon from 'presentations/assets/images/icons/icon_success.svg';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { convertDate, price } from 'utilities';

function VoucherCard({ usable, data, onSelectedVoucher, total, promo }) {
    const { t } = useTranslation();
    const [details, setDetails] = useState(false);
    const [buttonStyle, setButtonStyle] = useState(`bg-red-tsel hover:bg-red-500 text-white`);
    const [descriptionVoucher, setDescriptionVoucher] = useState(t('eligible_promo'));

    useEffect(() => {
        if (!usable) {
            setButtonStyle(`bg-gray-voucher_btn_disable text-gray-500`);
        } else if (promo === data.promotion_id) {
            setButtonStyle(`bg-white border border-red-tsel text-red-tsel`);
        } else {
            setButtonStyle(`bg-red-tsel hover:bg-red-500 text-white`);
        }
    }, [data, promo, usable]);

    useEffect(() => {
        if (data.minimum_applicable_amount > total) {
            setDescriptionVoucher(`${t('add')} Rp${price(data.minimum_applicable_amount - total)} ${t('more')}`);
        } else {
            setDescriptionVoucher(t('eligible_promo'));
        }
    }, [data, total, t]);

    return (
        <div className={`relative flex flex-col w-10/12 mt-4 ${usable ? 'bg-white' : 'bg-gray-100'}`}>
            <div
                className={`relative flex flex-col w-full h-auto border border-gray-300 rounded-xl p-4 z-10 ${
                    usable ? `bg-white` : `bg-gray-100`
                }`}
            >
                <div
                    className={`top-0 right-0 absolute py-1 px-2 border border-gray-300 rounded-tr-lg rounded-bl-lg ${
                        usable ? `bg-white` : `bg-gray-100`
                    }`}
                >
                    <img className="w-6" src={Gopay} alt="Promo Gopay" />
                </div>
                {data.limit_per_user > 1 && (
                    <div>
                        <img
                            className="absolute top-1 -left-2 w-10"
                            src={usable ? Ribbon : RibbonGray}
                            alt="Jumlah Voucher"
                        />
                        <span
                            className={`absolute top-2 left-1 w-8 text-xs font-face-poppinsbold font-semibold ${
                                usable ? `text-white` : `text-gray-desc`
                            } `}
                        >
                            {data.limit_per_user}x
                        </span>
                    </div>
                )}
                <div className="flex flex-col mt-3 pb-3 border-b border-dashed">
                    <h3 className={`${usable ? 'text-blue-dark_blue' : 'text-gray-voucher_text'}`}>{data.title}</h3>
                    <p className={`text-xs ${usable ? 'text-blue-dark_blue' : 'text-gray-voucher_text'}`}>
                        {t('voucher_until')} {convertDate(Date.parse(data.valid_till))}
                    </p>
                </div>
                {details && (
                    <ul className="mt-2 text-xs list-disc">
                        <li className="text-gray-600">
                            {t('minimum_transaction')} Rp{price(data.minimum_applicable_amount.split('.')[0])}
                        </li>
                        <li className="text-gray-600">
                            {t('promotion_value')} {data.additional_data.promotion_value.money.value}%
                        </li>
                        <li className="text-gray-600">{t('promotion_disclaimer')}</li>
                    </ul>
                )}
                <div className="flex items-center justify-between mt-2">
                    <div className="flex items-center" onClick={() => setDetails(!details)}>
                        <p className="text-xs">{details ? 'Tutup' : 'Lihat Detail'}</p>
                        {details ? (
                            <KeyboardArrowUpRounded fontSize="12" className="text-blue-dark_blue" />
                        ) : (
                            <KeyboardArrowDownRounded fontSize="12" className="text-blue-dark_blue" />
                        )}
                    </div>
                    <button
                        className={`rounded-3xl ${buttonStyle} p-3 w-1/3 text-button font-face-poppinsregular`}
                        onClick={onSelectedVoucher}
                    >
                        {promo === data.promotion_id ? t('remove') : t('use_promo')}
                    </button>
                </div>
            </div>
            <div
                className={`flex flex-grow items-center w-full border border-gray-300 rounded-xl p-4 pt-5 z-0 -mt-3  ${
                    usable ? `bg-white` : `bg-red-voucher_not_eligible`
                }`}
            >
                {usable ? (
                    <img className="w-5" src={SuccessIcon} alt="Voucher Dapat Digunakan" />
                ) : (
                    <CancelRoundedIcon fontSize="small" className="text-red-700" />
                )}
                <p className={`ml-2 text-xs ${usable ? 'text-green-voucher_eligible' : 'text-blue-dark_blue'}`}>
                    {descriptionVoucher}
                </p>
            </div>
        </div>
    );
}

export default VoucherCard;
