import React from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    TelegramShareButton,
    TelegramIcon,
    LineShareButton,
    LineIcon,
} from 'react-share';
import { shareUrl } from 'utilities';

function ModalShare({ onClose }) {
    const { t } = useTranslation();
    return (
        <>
            <div className="justify-center items-center flex overflow-hidden fixed bottom-0 lg:inset-0 z-50 outline-none focus:outline-none">
                <div className="opacity-75 fixed inset-0 z-51 bg-blue-dark_blue" onClick={onClose}></div>
                <div className="relative w-full mx-auto lg:max-w-none z-52">
                    {/*content*/}
                    <div className="border-0 lg:w-1/4 lg:mx-auto rounded-t-xl lg:rounded-b-xl shadow-lg relative flex flex-col w-screen bg-white outline-none focus:outline-none px-4 pb-12 pt-3">
                        <button
                            className="rounded-xl h-0.5 w-24 bg-gray-pull mx-auto lg:hidden hover:bg-gray-700"
                            onClick={onClose}
                        />
                        <button
                            className="hidden sm:block lg:flex lg:absolute lg:right-3 lg:top-3 text-gray-pull hover:text-gray-700"
                            onClick={onClose}
                        >
                            <CloseIcon color="inherit" />
                        </button>
                        {/*header*/}
                        <div className="flex flex-row items-center justify-center p-2 rounded-t mt-4">
                            <p className="font-face-tselbold">{t('share_social_media')}</p>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto w-full lg:w-auto mt-4 lg:mt-8 flex justify-around">
                            <FacebookShareButton url={shareUrl(Cookies.get('upp_token'))}>
                                <FacebookIcon size={36} className="rounded" />
                            </FacebookShareButton>
                            <WhatsappShareButton url={shareUrl(Cookies.get('upp_token'))}>
                                <WhatsappIcon size={36} className="rounded" />
                            </WhatsappShareButton>
                            <FacebookMessengerShareButton url={shareUrl(Cookies.get('upp_token'))}>
                                <FacebookMessengerIcon size={36} className="rounded" />
                            </FacebookMessengerShareButton>
                            <TelegramShareButton url={shareUrl(Cookies.get('upp_token'))}>
                                <TelegramIcon size={36} className="rounded" />
                            </TelegramShareButton>
                            <LineShareButton url={shareUrl(Cookies.get('upp_token'))}>
                                <LineIcon size={36} className="rounded" />
                            </LineShareButton>
                        </div>
                        {/*footer*/}
                        {/* <div className="flex items-center flex-col mt-6">
                            <button
                                className="bg-red-tsel w-full text-white active:bg-emerald-600 font-bold text-button p-4 rounded-button shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                                type="button"
                                // onClick={onBind}
                            >
                                {t('share')}
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalShare;
